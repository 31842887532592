<template>
  <div>
    <v-container fluid px-0 class="Container_Nivel2_Apuracao">
      <v-row align="center" justify="center" class="mx-0">
        <v-col cols="12">
          <v-text-field
            v-model="pesquisaCards"
            append-icon="search"
            @input="filtrar"
            :label="`${$tc('label.pesquisar', 1)}`"
            class="Barra_Pesquisa"
            single-line
            hide-details
            clearable>
          </v-text-field>
        </v-col>

        <v-container fluid grid-list-md v-if="lista.length">
          <v-data-iterator
            :items="lista"
            :options.sync="pagination"
            :hide-default-footer="12 >= totalPage"
            :server-items-length="totalPage"
            :footer-props="{
              itemsPerPageOptions: rowsPerPageItems,
            }">
            <template v-slot:default="props">
              <v-row justify="start">
                <v-col
                  v-for="item in props.items"
                  :key="item.cod_acao_previa"
                  cols="12"
                  sm="4"
                  md="3">
                  <v-hover v-slot:default="{ hover }">
                    <v-card :class="`Card_${item.status} elevation-${hover ? 5 : 1}`"
                            fill-height class="clickable Card_Apuracao">
                      <v-tooltip top>
                        <template v-slot:activator="{ on }">
                          <v-card-title class="Card_Title" v-on="on" @click="selectItem(item)">
                            <v-row justify="center" align="center">
                              <p style="text-align: center;">
                                  {{ item.foco_id | truncate(25) }}
                                <span>
                                  <br />{{ item.foco_nome | truncate(25) }}
                                  <br />{{ item.cod_acao_previa ? `Cod. Planejamento Massivo: ${item.cod_acao_previa}` : `Cod. Ação Tática: ${item.cod_acao}` }}
                                  <br />
                                  <br />{{ $tc('label.periodo_apuracao', 1) }}
                                  <br />{{item.data_inicio}} à {{item.data_fim}}
                                  <br />
                                  <div nowrap class="headline font-weight-light">
                                    {{ getMoney(item.vlr_conf_apuracao) }}
                                  </div>
                                </span>
                              </p>
                            </v-row>
                          </v-card-title>
                        </template>
                        <span v-if="(isVisaoStatus && (item.apuracoes_aprovadas === 0)
                          && (item.apuracoes_reprovadas === 0)
                          && (item.apuracoes_canceladas === 0)) ">
                          Status: {{ $tc('status_entidade.'+item.status.toLowerCase()) }}
                        </span>
                        <span v-if="(!isVisaoStatus && (item.apuracoes_aprovadas === 0)
                          && (item.apuracoes_reprovadas === 0)
                          && (item.apuracoes_canceladas === 0)) ">
                          Ainda não existem apurações aprovadas, reprovadas ou canceladas
                        </span>
                        <span v-if="((item.apuracoes_aprovadas === item.quantidade_acoes)
                          && (item.quantidade_acoes > 0)
                          && (item.apuracoes_reprovadas === 0)
                          && (item.apuracoes_canceladas === 0)) ">
                          Status: Apurações Aprovadas
                        </span>
                        <span v-if="((item.apuracoes_reprovadas === item.quantidade_acoes)
                          && (item.quantidade_acoes > 0)
                          && (item.apuracoes_aprovadas === 0)
                          && (item.apuracoes_canceladas === 0)) ">
                          Status: Apurações Reprovadas
                        </span>
                        <span v-if="((item.apuracoes_canceladas === item.quantidade_acoes)
                          && (item.quantidade_acoes > 0)
                          && (item.apuracoes_reprovadas === 0)
                          && (item.apuracoes_aprovadas === 0)) ">
                          Status: Apurações Canceladas
                        </span>
                        <span v-if="((item.apuracoes_aprovadas > 0)
                          && ((item.apuracoes_aprovadas !== item.quantidade_acoes) ||
                              ((item.apuracoes_reprovadas > 0) || (item.apuracoes_canceladas > 0)))
                          && (item.apuracoes_aprovadas >= item.apuracoes_reprovadas)
                          && (item.apuracoes_aprovadas >= item.apuracoes_canceladas)) ">
                          Status: Parcialmente Aprovado
                          <p v-if="(item.apuracoes_reprovadas > 0) || (item.apuracoes_canceladas > 0)">
                            Existem apurações reprovadas ou canceladas
                          </p>
                        </span>
                        <span v-if="((item.apuracoes_reprovadas > item.apuracoes_aprovadas)
                          && ((item.apuracoes_reprovadas !== item.quantidade_acoes) || (item.apuracoes_aprovadas > 0))
                          && (item.apuracoes_reprovadas >= item.apuracoes_canceladas)) ">
                          Status: Parcialmente Reprovado
                          <p v-if="item.apuracoes_aprovadas > 0">
                            Existem apurações aprovadas
                          </p>
                        </span>
                        <span v-if="((item.apuracoes_canceladas > item.apuracoes_aprovadas)
                          && ((item.apuracoes_canceladas !== item.quantidade_acoes) || item.apuracoes_aprovadas > 0)
                          && (item.apuracoes_canceladas >= item.apuracoes_reprovadas)) ">
                          Status: Parcialmente Cancelado
                          <p v-if="item.apuracoes_aprovadas > 0">
                            Existem apurações aprovadas
                          </p>
                        </span>
                      </v-tooltip>
                      <v-card-text class="Card_Content py-0" @click="selectItem(item)">
                        <span v-if="isVisaoStatus">
                          {{ `${item.quantidade_acoes > 0 ? item.quantidade_acoes : 1} ${retornaTextoStatus(item.quantidade_acoes, item.status)}` }}
                        </span>
                        <span v-else>
                          {{ `${item.quantidade_acoes > 0 ? item.quantidade_acoes : 1} ${item.quantidade_acoes > 1 ? 'Ações' : 'Ação'}` }}
                        </span>
                      </v-card-text>
                      <v-card-actions class="Card_Actions" v-if="item.cod_acao_previa">
                        <apuracao-acao-massiva-fluxo
                          class="-3"
                          v-if="canAccessAprovMass && ((item.apuracoes_aguardando_aprovacao > 0) || (item.apuracoes_aguardando_apuracao > 0)
                            || (item.apuracoes_em_analise > 0) || (item.apuracoes_vencidas > 0))"
                          :idAcaoMassiva="item.cod_acao_previa"
                          :status="item.status"
                          @AprovacaoMassivaApuracao_sucesso="reloadFluxoAtualizado()"
                          @LimpaSelecionados_sucesso="() => {}">
                        </apuracao-acao-massiva-fluxo>
                      </v-card-actions>
                    </v-card>
                  </v-hover>
                </v-col>
              </v-row>
            </template>
            <template v-slot:no-data>
              <v-row justify="center">
                {{ $t('label.tabela_sem_conteudo') }}
              </v-row>
            </template>
            <template v-slot:no-results>
              <v-row justify="center">
                {{ $t('label.tabela_sem_conteudo') }}
              </v-row>
            </template>
          </v-data-iterator>
        </v-container>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import ApuracaoAcaoMassivaFluxo from '@/produto/spa/apuracao-acao/ApuracaoAcaoMassivaFluxo';
import {
  getMoney,
  getPercent,
} from '../../common/functions/helpers';
import { generateComputed } from '../../common/functions/roles-computed-generator';
import {
  listarApuracoesNivel2,
  listarApuracoesNivel2Simplificado,
} from '../../common/resources/apuracao/apuracao-acao-listagem';

export default {
  name: 'ApuracaoAcaoListNivel2',
  components: {
    ApuracaoAcaoMassivaFluxo,
  },
  data() {
    return {
      pesquisaCards: '',
      status: null,
      idFornecedor: null,
      idFornecedorPagador: null,
      idGrupoFornecedor: null,
      idCliente: null,
      idHolding: null,
      idExterno: null,
      idExtensao: null,
      idRegional: null,
      mnemonico: null,
      origem: null,
      acao: {},
      iteratorKey: 0,
      totalPage: 0,
      lista: [],
      filtrosAplicados: {},
      rowsPerPageItems: [12],
      pagination: {
        itemsPerPage: 12,
      },
      buscando: false,
      esperarDigitar: false,
    };
  },
  watch: {
    filtroApuracaoAcao: {
      handler() {
        this.filtrosAplicados = this.filtroApuracaoAcao;
        this.buscar();
      },
    },
    pagination: {
      handler() {
        if (this.filtroApuracaoAcao) {
          this.buscar();
        }
      },
      deep: true,
    },
  },
  filters: {
    truncate(value, limit) {
      if (value && value.length > limit) {
        value = value.substring(0, (limit - 3)).concat('...');
      }
      return value;
    },
  },
  computed: {
    ...generateComputed('APU_ACAO', [
      'canAccessPage',
      'canAccessCRUD',
    ]),
    ...mapGetters('filtros', [
      'filtroApuracaoAcaoNivel2',
      'filtroApuracaoAcao',
    ]),
    ...mapGetters('implantacao', [
      'indModoVarejo',
    ]),
    isVisaoStatus() {
      return !!this.status;
    },
    canAccessAprovMass() {
      if (!this.getAllRoles) {
        return false;
      }
      return !!this.getAllRoles
        .filter((el) => el === 'APU_APROV_MASSIVO').length;
    },
    isVisaoFornecedor() {
      return !!this.idFornecedor;
    },

    isVisaoFornecedorPagador() {
      return !!this.idFornecedorPagador;
    },

    isVisaoGrupoFornecedor() {
      return !!this.idGrupoFornecedor;
    },

    isVisaoCliente() {
      return !!this.idCliente;
    },

    isVisaoHolding() {
      return !!this.idHolding;
    },

    isVisaoRegional() {
      return !!this.idRegional;
    },

    isVisaoExtensao() {
      return !!this.mnemonico && !!this.idExterno;
    },
  },
  methods: {
    getMoney,
    getPercent,
    ...mapActions('filtros', [
      'setFiltroApuracaoAcaoNivel2',
    ]),
    filtrar() {
      if (this.esperarDigitar) return;
      this.esperarDigitar = true;
      setTimeout(() => {
        this.esperarDigitar = false;
        this.buscar();
      }, 5E2);
    },
    selectItem(item) {
      this.consultar(listarApuracoesNivel2Simplificado, (response) => {
        const listaSimplificada = response.data;

        let params;

        if ((item.cod_acao_previa !== undefined) && (item.cod_acao_previa !== null) && (item.cod_acao_previa > 0)) {
          params = {
            idAcaoPrevia: item.cod_acao_previa,
            apuracoes: listaSimplificada,
            from: 'apuracao-acao',
          };
        } else {
          params = {
            idAcao: item.cod_acao,
            apuracoes: listaSimplificada,
            from: 'apuracao-acao',
          };
        }

        let query;

        if (this.isVisaoStatus) {
          params.status = this.status;
          if (params.idAcaoPrevia) {
            this.$router.push({ name: 'listarApuracoesAcaoMassivaNivel3Status', params, query });
          } else {
            this.$router.push({ name: 'listarApuracoesAcaoNivel3Status', params, query });
          }
        }

        query = { origem: this.origem, textoOrigem: this.origem };

        if (this.isVisaoFornecedor) {
          params.idFornecedor = this.idFornecedor;
          this.$router.push({ name: 'detalharApuracaoAcaoFornecedor', params, query });
        }

        if (this.isVisaoFornecedorPagador) {
          params.idFornecedorPagador = this.idFornecedorPagador;
          this.$router.push({ name: 'detalharApuracaoAcaoFornecedorPagador', params, query });
        }

        if (this.isVisaoGrupoFornecedor) {
          params.idGrupoFornecedor = this.idGrupoFornecedor;
          this.$router.push({ name: 'detalharApuracaoAcaoGrupo', params, query });
        }

        if (this.isVisaoCliente) {
          params.idCliente = this.idCliente;
          this.$router.push({ name: 'listarApuracoesAcaoNivel3Cliente', params, query });
        }

        if (this.isVisaoHolding) {
          params.idHolding = this.idHolding;
          this.$router.push({ name: 'listarApuracoesAcaoNivel3Holding', params, query });
        }

        if (this.isVisaoRegional) {
          params.idRegional = this.idRegional;
          this.$router.push({ name: 'detalharApuracaoAcaoRegional', params, query });
        }

        if (this.isVisaoExtensao) {
          params.mnemonico = this.mnemonico;
          params.idExterno = this.idExterno;
          this.$router.push({ name: 'detalharApuracaoAcaoExtensao', params, query });
        }
      });
    },
    retornaTextoStatus(quantidade, status) {
      let texto = '';

      if (status === 'APROVADO') {
        if (quantidade > 1) {
          texto = 'Ações Aprovadas';
        } else {
          texto = 'Ação Aprovada';
        }
      } else if (status === 'AGUARDANDO_APURACAO') {
        if (quantidade > 1) {
          texto = 'Ações Aguardando Apuração';
        } else {
          texto = 'Ação Aguardando Apuração';
        }
      } else if (status === 'AGUARDANDO_APROVACAO') {
        if (quantidade > 1) {
          texto = 'Ações Aguardando Aprovação';
        } else {
          texto = 'Ação Aguardando Aprovação';
        }
      } else if (status === 'EM_ANALISE') {
        if (quantidade > 1) {
          texto = 'Ações em Análise';
        } else {
          texto = 'Ação em Análise';
        }
      } else if (status === 'CANCELADO') {
        if (quantidade > 1) {
          texto = 'Ações Canceladas';
        } else {
          texto = 'Ação Cancelada';
        }
      } else if (status === 'REPROVADO') {
        if (quantidade > 1) {
          texto = 'Ações Reprovadas';
        } else {
          texto = 'Ação Reprovada';
        }
      } else if (status === 'VENCIDO') {
        if (quantidade > 1) {
          texto = 'Ações Vencidas';
        } else {
          texto = 'Ação Vencida';
        }
      } else if (status === 'APURACAO_PREVIA') {
        if (quantidade > 1) {
          texto = 'Ações em Apuração Prévia';
        } else {
          texto = 'Ação em Apuração Prévia';
        }
      }

      return texto;
    },
    reloadFluxoAtualizado() {
      this.lista = [];

      if (this.filtroApuracaoAcao) {
        this.filtrosAplicados = this.filtroApuracaoAcao;
      }

      this.buscar();
    },
    buscar() {
      this.consultar(listarApuracoesNivel2, (response) => {
        this.totalPage = response.data.quantidadeRegistrosPagina;
        this.lista = response.data.resposta;
        this.lista.forEach((i) => {
          i.acao = {};
          i.acao.idAcao = i.cod_acao;
          i.acao.idApuracao = i.cod_apuracao;
          const desAtributos = i.des_atributos
            ? JSON.parse(i.des_atributos.value) : {};
          i.acao = {
            ...i.acao,
            ...desAtributos,
          };
        });
        this.rowsPerPageItems = [12, 24, 36, 48, 60, 72, 84];
        setTimeout(this.finalizandoBusca);
        this.preencherFiltrosNivel1Vuex();

        const filtrosTotalizador = {
          ...this.filtrosAplicados,
          id_acao_previa: null,
        };

        this.$emit('ApuracaoAcaoFiltro__recarregarTotalizador', filtrosTotalizador);
      });
    },
    consultar(consulta, sucesso) {
      if (this.buscando) return;
      this.buscando = true;

      const params = {
        ...this.filtrosAplicados,
        filtro: this.pesquisaCards,
        tamanhoPagina: this.pagination.itemsPerPage,
        numeroPagina: this.pagination.page,
      };

      if (this.isVisaoFornecedor) {
        params.id_fornecedor = this.idFornecedor;
      } else if (this.isVisaoFornecedorPagador) {
        params.id_fornecedor_pagador = this.idFornecedorPagador;
      } else if (this.isVisaoGrupoFornecedor) {
        params.id_grupo_fornecedor = this.idGrupoFornecedor;
      } else if (this.isVisaoCliente) {
        params.id_cliente = this.idCliente;
      } else if (this.isVisaoHolding) {
        params.id_holding = this.idHolding;
      } else if (this.isVisaoStatus) {
        params.status = this.status;
      } else if (this.isVisaoRegional) {
        params.id_regional = this.idRegional;
      } else {
        params.id_externo = this.idExterno;
        params.mnemonico = this.mnemonico;
      }

      consulta(params, this.$resource)
        .then(sucesso)
        .catch((err) => {
          this.finalizandoBusca();
          this.$error(this, err);
        });
    },
    preencherFiltrosNivel2() {
      this.pagination.page = this.filtroApuracaoAcaoNivel2.pagina;
      this.pagination.itemsPerPage = this.filtroApuracaoAcaoNivel2.quantidatePorPagina;
    },
    preencherFiltrosNivel1Vuex() {
      const pagina = this.pagination.page;
      const quantidatePorPagina = this.pagination.itemsPerPage;

      this.setFiltroApuracaoAcaoNivel2({
        pagina,
        quantidatePorPagina,
      });
    },
    finalizandoBusca() {
      this.buscando = false;
      this.$forceUpdate();
    },
    preencherFiltrosRouter() {
      this.status = this.$route.params.status;
      this.idFornecedor = this.$route.params.idFornecedor;
      this.idFornecedorPagador = this.$route.params.idFornecedorPagador;
      this.idGrupoFornecedor = this.$route.params.idGrupoFornecedor;
      this.idCliente = this.$route.params.idCliente;
      this.idHolding = this.$route.params.idHolding;
      this.idExterno = this.$route.params.idExterno;
      this.idRegional = this.$route.params.idRegional;
      this.idExtensao = this.$route.params.idExtensao;
      this.mnemonico = this.$route.params.mnemonico;
      this.origem = this.$route.query.origem;
    },
    isOrigemOuFocoExtensao(item) {
      if ((this.indModoVarejo && !item.is_origem_fornecedor)
         || (!this.indModoVarejo && !item.is_foco_cliente && !item.is_foco_regional)) {
        return true;
      }
      return false;
    },
  },
  beforeMount() {
    this.preencherFiltrosRouter();
  },
  mounted() {
    if (this.filtroApuracaoAcaoNivel2) {
      this.preencherFiltrosNivel2();
    }
    if (this.filtroApuracaoAcao) {
      this.filtrosAplicados = this.filtroApuracaoAcao;
    }
    this.buscar();
  },
};
</script>

<style>
.Container_Nivel2_Apuracao .Card_Apuracao {
  min-height: 146px !important;
  height: 100%;
}
.Container_Nivel2_Apuracao .Card_Title, .Container_Nivel2_Apuracao .Card_Content {
  color: #757575;
}

.Barra_Pesquisa {
  width: 33%;
}
.clickable {
  cursor: pointer;
}
</style>
<style src="../../assets/css/card-status.css" lang="css"></style>
